import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../context/appContext";
import { postService } from "../../services/script-api";
import { compileMaterialsOptions, compileOptions } from "../../views/ChaptersAndEpisodes/compileOptions";
import BoxFooter from "../BoxFooter/BoxFooter";
import Button from "../Button/Button";
import ScriptBox from "../ScriptBox/ScriptBox";
import SimpleButton from "../SimpleButton/SimpleButton";
import UploadSection from "../UploadSection/UploadSection";
import UploadService from "../UploadService/UploadService";
import AttachmentIcon from "../VectorIcons/AttachmentIcon";
import UploadIcon from "../VectorIcons/UploadIcon";
import "./style/UploadBox.scss";

const UploadBox = ({
  title = "dos arquivos",
  projectId,
  listItems,
  getPlanning,
  isChapter,
  isMaterial,
}) => {
  const appContext = useContext(AppContext);
  const [stateFiles, setStateFiles] = useState([]);

  const isDramaturgy = appContext.current.step === "dramatologyAttachments";
  const hasBlocks = listItems.blocks && listItems.blocks[0]?.blockNumber;
  const hasEpisodes = listItems.episodes && listItems.episodes[0]?.episodeNumber;
  const hasMaterial = listItems.episodes && listItems.episodes[0]?.episodeNumber !== null;

  const warningMessage = isMaterial 
  ? "Atenção, é necessário criar um planejamento antes de subir um arquivo!" 
  : isChapter
    ? "Atenção, é necessário criar um bloco antes de subir um arquivo!"
    : "Atenção, é necessário criar um episódio antes de subir um arquivo!";

  const dramaturgyList = compileOptions(listItems);
  let materialsList;
  if (isMaterial) {
    materialsList = compileMaterialsOptions(listItems);
  }

  useEffect(() => {
    const shouldShowWarning = (isChapter && !hasBlocks) || (isMaterial && !hasMaterial) || (!isChapter && !isMaterial && !hasEpisodes);

    if (shouldShowWarning) {
      appContext.sendMessage(warningMessage, "warning");
    }
    // eslint-disable-next-line
  }, [isChapter, listItems]);

  const getFiles = (files) => {
    setStateFiles({
      ...stateFiles,
      files,
    });
  };

  const getDuplicateFiles = (files) => {
    let filesName = "";
    files
      .flat()
      .map(
        (e) => (filesName += `<p>${e.originalName} ja foi selecionado!</p>`)
      );
    appContext.sendMessage(filesName, "error");
  };

  const sendFile = () => {
    appContext.setShowGlassLoading(true);

    let obj = stateFiles.files.map((file) => ({
      contentId: projectId,
      fileId: file._meta.tempFileName,
      fileName: file.originalName,
      version: file.version,
      receivedAt:
        appContext.transformDateToUTC(file.receiveAt) || file.receiveAt || null,
      attachmentTypeId: file.attachmentTypeId,
      blockNumber: file?.blockNumber || 1,
      chapterNumber: file.chapterNumber,
      numberChaptersBlock:
        file?.numberChaptersBlock || file?.numberAttachmentsEpisodes,
      rescheduledDelivery: file?.rescheduledDelivery,
      rescheduledRelease: file?.rescheduledRelease,
      scheduleDelivery: file?.scheduleDelivery,
      scheduleRelease: file?.scheduleRelease,
      genresIds: [0],
      spacesIds: [0],
      release: 0,
      contentOrigns: [
        {
          contentOriginId: 0,
          contentOriginDescriptions: ["string"],
        },
      ],
      narratives: [0],
      reason: file.reason,
      structuringReview: file.structuringReview
    }));

    postService("contents/attachments", obj)
      .then(() => {
        appContext.setShowGlassLoading(false);
        getPlanning();
        appContext.sendMessage("Arquivo cadastrado com sucesso!");
        appContext.setCurrentStepBox("closed");
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage("Erro ao cadastrar o arquivo!", "error");
        appContext.setCurrentStepBox("closed");
      });
  };

  const uploadFilesIsValid = () => {
      if (!!stateFiles.files && stateFiles.files.length > 0 && stateFiles?.files[0].chapterNumber > 0) {
        if(stateFiles?.files[0].structuringReview && stateFiles?.files[0].reason !== 'placeholder' && stateFiles?.files[0].reason){
          return (
            stateFiles.files.every((file) => !!file.attachmentTypeId) &&
            stateFiles.files.every((stateFile) => stateFile.isSent === true)
          );
        }
        if(!stateFiles?.files[0].structuringReview){
          return (
            stateFiles.files.every((file) => !!file.attachmentTypeId) &&
            stateFiles.files.every((stateFile) => stateFile.isSent === true)
          );
        }
      return false
      }
  };

  const scriptBoxTitle = `Novos Anexos de ${isMaterial ? 'Materiais' :
    isChapter ? 'Capítulos' :  'Episódios'
  }`;

  return (
    <div id="UploadBox">
      <div className="newActivity">
        <span className="newActivityText">{`Faça upload ${title}`}</span>
        <SimpleButton
          text="Escolher arquivos..."
          classButton="buttonBlue extraLarge"
          dataQA=""
          toValidate={true}
          isValidForm={
            isMaterial? hasMaterial
            : isChapter ? hasBlocks : hasEpisodes}
          disabledMessage={warningMessage}
          appContext={appContext}
          clickEvent={() =>
            appContext.setCurrentStepBox("dramatologyAttachments")
          }
        >
          <UploadIcon />
        </SimpleButton>
      </div>
      {isDramaturgy && (
        <ScriptBox
          open={isDramaturgy}
          title={scriptBoxTitle}
          icon={<AttachmentIcon color="#FFF" />}
          marginBottom={true}
        >
          <div className="scriptBoxUpload">
            <UploadSection title={`Anexe aqui os ${isChapter ? 'capítulos' : 'episódios'} recebidos`}>
              <UploadService
                thesaurusFileType={appContext.currentOriginFileType.result}
                handleOnComplete={getFiles}
                getDuplicateFiles={getDuplicateFiles}
                inputName={isMaterial ? "Movie" : "Dramaturgy"}
                dramaturgyList={dramaturgyList}
                materialsList={materialsList}
                isChapter={isChapter}
                isMaterial={isMaterial}
              />
            </UploadSection>
          </div>

          <BoxFooter>
            <Button
              text="Salvar"
              toValidate={true}
              isValidForm={uploadFilesIsValid()}
              classButton="buttonGreen large"
              typeButton="submit"
              dataQA="button-prospect-edit"
              clickEvent={sendFile}
            />
          </BoxFooter>
        </ScriptBox>
      )}
    </div>
  );
};

export default UploadBox;
