import React, { useContext, useEffect, useState } from "react";
import Switch from "../../../components/Switch/Switch";
import CustomDatePicker from "../../../components/CustomDatePicker/CustomDatepicker";
import SelectOriginalProduct from "../../../components/SelectOriginalProduct/SelectOriginalProduct";
import Fieldset from "../../../components/Fieldset/Fieldset";
import HelpLabel from "../../../components/HelpLabel/HelpLabel";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import NumberInput from "../../../components/NumberInput/NumberInput";
import SimpleButton from "../../../components/SimpleButton/SimpleButton";
import NextArrowIcon from "../../../components/VectorIcons/NextArrowIcon";
import { getService } from "../../../services/script-api";
import CheckBox from "../../../components/CheckBox/CheckBox";
import RegisterContext from "../../../context/registerContext";
import ClientDetails from "../../../components/ClientDetails/ClientDetails";
import SmallLoading from "../../../components/SmallLoading/SmallLoading";
import ContentCreateProspects from "../../../components/ContentCreateProspects/ContentCreateProspects";
import ContentCreatorProductCompanies from "../../../components/ContentCreatorProductCompanies/ContentCreatorProductCompanies";

const Step1 = (props) => {
  const {
    setStateCurrentStep,
    getCreators,
    getProducts,
    getDramaturgy,
    getOrigin,
    getSeason,
    getFormat,
    getClients,
    type,
  } = props;

  const registerContext = useContext(RegisterContext);

  const newDate = new Date();
  const currentDate = new Date(
    newDate.getTime() - newDate.getTimezoneOffset() * 60000
  ).toISOString();
  const initialDramaturgy = {
    id: "",
    title: "",
    receivedAt: currentDate,
    schedule: "",
    chapter: "",
  };
  const initialStateSeasonNumber = {
    seasonNumber: "",
    currentStateSeasonNumber: 1,
    productId: "",
  };
  const initialFormat = { id: "", label: "" };
  const initialErrorFields = {
    title: false,
    receivedAt: false,
    createBy: false,
    origin: false,
    format: false,
    seasonNumber: false,
    originalProduct: false,
  };

  const [stateNewSeason, setStateNewSeason] = useState(false);
  const [stateDramaturgy, setStateDramaturgy] = useState(initialDramaturgy);
  const [stateSeasonNumber, setStateSeasonNumber] = useState(
    initialStateSeasonNumber
  );
  const [stateFormat, setStateFormat] = useState(initialFormat);
  const [stateError, setStateError] = useState(initialErrorFields);
  const [currentCreators, setCurrentCreators] = useState(null);
  const [stateProducts, setStateProducts] = useState([]);
  const [stateOrigins, setStateOrigins] = useState([]);
  const [stateChannels, setStateChannels] = useState([]);

  useEffect(() => {
    if (!stateNewSeason) setStateSeasonNumber(initialStateSeasonNumber);
    getSeason(initialStateSeasonNumber);
    // eslint-disable-next-line
  }, [stateNewSeason]);

  useEffect(() => {
    registerContext.listOrigins.result.forEach((res) => {
      res.isChecked = false;
    });
    setStateOrigins(registerContext.listOrigins.result);
  }, [registerContext.listOrigins.result]);

  useEffect(() => {
    setStateError({
      ...stateError,
      seasonNumber: stateSeasonNumber.seasonNumber.includes(
        stateSeasonNumber.currentStateSeasonNumber
      ),
    });
    // eslint-disable-next-line
  }, [stateSeasonNumber]);

  const toggleSeason = () => setStateNewSeason(!stateNewSeason);

  const handleChangeDate = (date) => {
    if (!!date) {
      let finalDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toISOString();
      setStateDramaturgy({ ...stateDramaturgy, receivedAt: finalDate });
      getDramaturgy("receivedAt", finalDate);
    } else {
      setStateDramaturgy({ ...stateDramaturgy, receivedAt: "" });
      getDramaturgy("receivedAt", "");
    }

    setStateError({
      ...stateError,
      receivedAt: !date,
    });
  };

  const tooltipContent = () => {
    return (
      <ul>
        <li>O Outro Lado do Paraíso</li>
        <li>Tá no Ar: a TV na TV</li>
        <li>Treze Dias Longe do Sol</li>
      </ul>
    );
  };

  const handleClearOriginalProduct = () => {
    setStateSeasonNumber(initialStateSeasonNumber);
    setStateFormat(initialFormat);
    getSeason(initialStateSeasonNumber);
    getFormat(initialFormat);
  };

  const handleChangeSetOriginalProduct = (item) => {
    let obj = {
      productId: item.value,
      seasonNumber: item.seasonNumbers,
      currentStateSeasonNumber: returnNextValidSeason(item.seasonNumbers),
      formatId: { id: parseInt(item.formatId), label: item.format },
    };

    setStateSeasonNumber({
      ...stateSeasonNumber,
      ...obj,
    });

    setStateFormat({
      ...stateFormat,
      id: parseInt(item.formatId),
      label: item.format,
    });

    getFormat({
      ...stateFormat,
      id: parseInt(item.formatId),
      label: item.format,
    });

    getSeason(obj);
  };

  const returnNextValidSeason = (array) => {
    // eslint-disable-next-line
    let nextValidSeason = 0;

    array.every((number, index) => {
      if (number + 1 === array[index + 1]) {
        return true;
      } else {
        nextValidSeason = array[index] + 1;
        return false;
      }
    });
    return nextValidSeason;
  };

  const handleChangeDramaturgy = (ev) => {
    setStateDramaturgy({ ...stateDramaturgy, title: ev.target.value });
    getDramaturgy("title", ev.target.value);
  };

  const handleBlurDramaturgy = (ev) => {
    const fieldIsEmpty = ev.target.value.trim() === "";
    const fieldIsIncomplete = ev.target.value.trim().length < 2;

    const handleMessageError = () => {
      if (fieldIsIncomplete) {
        return !!fieldIsEmpty
          ? "Este campo é obrigatório"
          : "O nome precisa ter pelo menos 2 caracteres";
      }
    };

    setStateError({
      ...stateError,
      title: {
        isError: fieldIsEmpty || fieldIsIncomplete,
        errorMessage: handleMessageError(),
      },
    });
  };

  const _getSeason = (season) => {
    setStateSeasonNumber({
      ...stateSeasonNumber,
      currentStateSeasonNumber: season,
    });

    getSeason({
      ...stateSeasonNumber,
      currentStateSeasonNumber: season,
    });
  };

  const _getOrigins = (key) => {
    const newState = [...stateOrigins];

    let origin = newState.filter((e) => e.id === key)[0];
    origin.isChecked = !origin.isChecked;

    let length = newState.filter((el) => !el.isChecked);
    setStateError({
      ...stateError,
      origin: length.length === 2,
    });
    setStateOrigins(newState);
    getOrigin(filterOrigins());
  };

  const haveNewSeason = () => {
    return stateNewSeason
      ? !!stateSeasonNumber.productId &&
          !!stateSeasonNumber.currentStateSeasonNumber &&
          !stateError.seasonNumber
      : true;
  };

  const _setCurrentCreators = (value) => {
    setCurrentCreators(value);
    getCreators(value);
  };

  useEffect(() => {
    setStateError((prevState) => ({
      ...prevState,
      createBy: !!currentCreators && !currentCreators?.length,
    }));
  }, [currentCreators]);

  const step1isValid = () => {
    return (
      haveNewSeason() &&
      !!stateDramaturgy.receivedAt &&
      !!currentCreators?.length &&
      !!stateDramaturgy.title &&
      !stateError.title.isError &&
      stateOrigins.some((checked) => !!checked.isChecked)
    );
  };

  const handleChangeProductions = (productions) => {
    setStateProducts(productions);
    const productionsIds = productions.map((item) => item.value);
    getProducts(productionsIds);
  };

  const filterOrigins = () => {
    let _array = [];
    stateOrigins.forEach((el) => {
      if (el.isChecked) {
        _array.push(el.id);
      }
    });

    return _array;
  };

  const getThesaurusChannels = () => {
    getService("dramaturgies/thesaurus?origins=28").then((res) => {
      setStateChannels(res.data);
    });
  };

  useEffect(() => getThesaurusChannels(), []);

  return (
    <>
      <div className="formField">
        <Switch
          blockLabel="É uma nova temporada?"
          event={() => toggleSeason()}
          switchOn={stateNewSeason}
        />

        <div className="dateField">
          <CustomDatePicker
            label="Recebido Em"
            name="receivedAt"
            date={
              !!stateDramaturgy.receivedAt
                ? new Date(stateDramaturgy.receivedAt)
                : ""
            }
            handleChangeDate={handleChangeDate}
          />

          {stateError.receivedAt && (
            <span className="errorMessage">Este campo é obrigatório</span>
          )}
        </div>
      </div>

      {stateNewSeason && (
        <div className="formFieldColumn">
          <label htmlFor="products">Produto Original</label>

          <SelectOriginalProduct
            placeholder="Digitar um título"
            handleChange={handleChangeSetOriginalProduct}
            handleClear={handleClearOriginalProduct}
            isClearable={true}
            type={type}
          />

          {stateError.originalProduct && (
            <span className="errorMessage">Este campo é obrigatório</span>
          )}
        </div>
      )}

      <div className="formField">
        <div className="formFieldColumn titleNumber">
          <Fieldset
            autoComplete="off"
            name="seatonTitle"
            label={`${stateNewSeason ? "Título de Temporada" : "Título"}`}
            type="text"
            placeholder={`${
              stateNewSeason
                ? "Digite o nome da Temporada"
                : "Digite o nome do projeto"
            }`}
            value={stateDramaturgy.title}
            handleChange={handleChangeDramaturgy}
            handleBlur={handleBlurDramaturgy}
            isError={stateError.title.isError}
            msgError={stateError.title.errorMessage}
          />

          <div className="helpContainer">
            <HelpLabel
              label="Utilize letra maiúscula no início das palavras, exceto preposições.&nbsp;"
              tipLabel="Ver exemplos"
            >
              <CustomTooltip
                tipLabel="Ver exemplos"
                dataTip={tooltipContent()}
              />
            </HelpLabel>
          </div>
        </div>

        {stateNewSeason && (
          <NumberInput
            label="Nº da Temporada"
            number={stateSeasonNumber.currentStateSeasonNumber}
            getVersion={_getSeason}
            error={stateError.seasonNumber}
            errorMessage="Temporada já existente"
          />
        )}
      </div>

      <div className="formFieldColumn">
        <ContentCreateProspects
          getCreators={_setCurrentCreators}
          label="Criado por:"
          placeholder="Digitar um nome e apertar ENTER"
          isError={stateError.createBy}
        />
      </div>

      <div className="formFieldColumn">
        <ContentCreatorProductCompanies
          getProductionCompanies={handleChangeProductions}
          label="Produtora:"
          placeholder="Digitar um nome e apertar ENTER"
          isError={false}
          data={stateProducts}
        />

        <span className="optionalText">Opcional</span>
      </div>

      <div className="formField">
        <span className="checkBoxContainer">
          <span className="fieldTitle">Procedência</span>

          <div className="checkBoxes">
            {!!stateOrigins.length ? (
              stateOrigins.map((item, i) => {
                return (
                  <CheckBox
                    key={i}
                    index={i}
                    value={item.id}
                    label={item.value}
                    event={_getOrigins}
                  />
                );
              })
            ) : (
              <SmallLoading />
            )}
          </div>

          {stateError.origin && (
            <span className="errorMessage">Este campo é obrigatório</span>
          )}
        </span>
      </div>

      <div className="formFieldColumn">
        <span className="fieldTitle">Produto/Canais</span>

        <ClientDetails details={stateChannels} getClients={getClients} />
      </div>

      <div className="formField buttonField">
        <SimpleButton
          text="Avançar"
          classButton="buttonBlue extraLarge"
          dataQA="button-prospect-edit"
          iconSide="right"
          isValidForm={step1isValid()}
          toValidate={true}
          clickEvent={step1isValid() ? () => setStateCurrentStep(2) : () => {}}
        >
          <NextArrowIcon />
        </SimpleButton>
      </div>
    </>
  );
};

export default Step1;
