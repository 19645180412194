import React, { useState, useContext } from "react";
import "./style/CreateAndUpdateBlockPlanning.scss";
import { postService, putService } from "../../../services/script-api";
import FormAndFields from "./FormAndFields/FormAndFields";
import AppContext from "../../../context/appContext";
import Contracts from "../Contracts/Contracts";
import { sendEvent } from "../../../services/GoogleAnalytics/methods";

const CreateAndUpdateBlockPlanningMaterials = (props) => {
  const { action, planningData, contentId, getPlanning, chapterOrEpisode, nextValidArgumentEpisode } = props;
  const appContext = useContext(AppContext);

  const initialStateCreate = {
    attachmentTypeId: 1209,
    scheduleDelivery: "",
    scheduleRelease: "",
  };

  const initialStateEdit = {
    scheduleDelivery: action.data?.scheduleDelivery
      ? new Date(action.data.scheduleDelivery)
      : "",
    rescheduledDelivery: action.data?.rescheduledDelivery
      ? new Date(action.data.rescheduledDelivery)
      : "",
    scheduleRelease: action.data?.scheduleRelease
      ? new Date(action.data.scheduleRelease)
      : "",
    rescheduledRelease: action.data?.rescheduledRelease
      ? new Date(action.data.rescheduledRelease)
      : "",
    attachmentTypeId: action.data?.attachmentTypeId || 1209,
  };

  const initialStateTypes = {
    create: initialStateCreate,
    edit: initialStateEdit,
  };

  const [state, setState] = useState(initialStateTypes[action.type]);

  const handleChangeDate = (date, type) => {
    setState({
      ...state,
      [type]: date,
    });
  };

  const handleCreateSubmit = (ev) => {
    ev.preventDefault();
    appContext.setShowGlassLoading(true);

    const episodeNumber = nextValidArgumentEpisode(state.attachmentTypeId, planningData);

    const postObj = Contracts.postBlock({
      ...state,
      contentId,
      attachmentTypeId: parseInt(state.attachmentTypeId),
      blockNumber: planningData?.nextValidBlock,
      episodeNumber,
      scheduleDelivery: state.scheduleDelivery || null,
      scheduleRelease: state.scheduleRelease || null,
      version: episodeNumber,
    });

    postService(planningData.endpoints.post, [postObj])
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(planningData.messages.postMessages.success);
        appContext.setCurrentStepBox("closed");
        sendEvent(
          planningData.analytics.post.category,
          planningData.analytics.post.action
        );
        getPlanning();
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          planningData.messages.postMessages.error,
          "error"
        );
      });
  };

  const handleStartDates = {
    create: {
      scheduleDelivery: "",
      scheduleRelease: state.scheduleDelivery,
    },
    edit: {
      scheduleDelivery: "",
      rescheduledDelivery: state.scheduleDelivery,
      scheduleRelease: state.scheduleDelivery,
      rescheduledRelease:
        state.scheduleRelease ||
        state.rescheduledDelivery ||
        state.scheduleDelivery,
      plannedContentSight: "",
      plannedProdutionSight: "",
      plannedArtisticApproval: "",
      plannedChannelFeedback: "",
    },
  };

  const handleMaxDates = {
    create: {
      scheduleDelivery: state.scheduleRelease,
      scheduleRelease: "",
    },
    edit: {
      scheduleDelivery:
        state.rescheduledDelivery ||
        state.scheduleRelease ||
        state.rescheduledRelease,
      rescheduledDelivery: state.rescheduledRelease,
      scheduleRelease: state.rescheduledRelease,
      rescheduledRelease: "",
      plannedContentSight: "",
      plannedProdutionSight: "",
      plannedArtisticApproval: "",
      plannedChannelFeedback: "",
    },
  };

  const handleEditSubmit = (ev) => {
    ev.preventDefault();
    appContext.setShowGlassLoading(true);

    const putObj = Contracts.PutBlock({
      ...state,
      contentId,
      id: action?.data?.id,
      blockNumber: action?.data?.blockNumber,
      episodeNumber: action?.data?.episodeNumber,
      version: action?.data?.version,
      attachmentTypeId: parseInt(action?.data?.attachmentType?.id),
      scheduleDelivery: state.scheduleDelivery || null,
      rescheduledDelivery: state.rescheduledDelivery || null,
      scheduleRelease: state.scheduleRelease || null,
      rescheduledRelease: state.rescheduledRelease || null,
      plannedContentSight: state.plannedContentSight || null,
      plannedProdutionSight: state.plannedProdutionSight || null,
      plannedArtisticApproval: state.plannedArtisticApproval || null,
      plannedChannelFeedback: state.plannedChannelFeedback || null,
    });

    putService(planningData.endpoints.put, putObj)
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(planningData.messages.putMessages.success);
        appContext.setCurrentStepBox("closed");
        sendEvent(
          planningData.analytics.post.category,
          planningData.analytics.post.action,
          planningData.analytics.post.label
        );
        getPlanning();
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(
          planningData.messages.putMessages.error,
          "error"
        );
      });
  };

  const setAction = {
    create: (ev) => handleCreateSubmit(ev),
    edit: (ev) => handleEditSubmit(ev),
  };

  return (
    <div id="CreateAndUpdateBlockPlanning">
      <FormAndFields
        action={action}
        handleChangeDate={handleChangeDate}
        handleSubmit={setAction[action.type]}
        handleStartDates={handleStartDates}
        handleMaxDates={handleMaxDates}
        values={state}
        handleValidate={true}
        planningData={planningData}
        isMaterial={true}
        chapterOrEpisode={chapterOrEpisode}
        nextValidArgumentEpisode={nextValidArgumentEpisode}
      />
    </div>
  );
};

export default CreateAndUpdateBlockPlanningMaterials;
