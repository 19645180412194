import React from "react";
import "./styles/BlocksOrChaptersTable.scss";
import TableItemMaterial from "./TableItem/TableItemMaterial";
import EpisodesHeader from "./EpisodesHeader/EpisodesHeader";

const MaterialsTable = (props) => {
  const {
    list,
    setStateAction,
    typeValues,
    handleOpenReleaseTexts,
    handleEditFile,
    getPlanning,
    handleDeleteMaterial,
    type,
    handleFileDetails,
    projectTitle,
    isReleased,
  } = props;

  const TYPES = [
    { id: 1209, title: "Argumento" },
    { id: 1210, title: "Escaleta" },
    { id: 18738, title: "Roteiro" },
  ];

  const filterByAttachmentType = (id) => {
    return list.filter((item) => item?.attachmentType?.id === id);
  };

  return (
    <div id="BlocksOrChaptersTable">
      <div className="notMobile">
        {!isReleased && <EpisodesHeader />}
      </div>

      <ul>
        {TYPES.map(({ id, title }) => {
          const items = filterByAttachmentType(id);
          return (
            <div key={id}>
              <TableItemMaterial
                key={id}
                items={items}
                mainCellTitle={title}
                setStateAction={setStateAction}
                typeValues={typeValues}
                getPlanning={getPlanning}
                handleFileDetails={handleFileDetails}
                handleEditFile={handleEditFile}
                handleOpenReleaseTexts={handleOpenReleaseTexts}
                handleDeleteMaterial={handleDeleteMaterial}
                type={type}
                isMaterial={true}
                projectTitle={projectTitle}
                isReleased={isReleased} />
              <br />
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default MaterialsTable;
