/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { getService } from "../../services/script-api";
import AsyncLoading from "../AsyncLoading/AsyncLoading";
import Option from "../AssociateFieldset/Option/Option";
import { handleDemandTypeByEntityType } from "../../shared/utils/handleDemandTypeByEntityType";

const SelectOriginalProduct = ({
  handleChange,
  handleClear,
  placeholder = "Selecione",
  type,
  isClearable,
}) => {
  const initialStateProducts = { create: [], load: [] };

  const [stateProducts, setStateProducts] = useState(initialStateProducts);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageInfo, setPageInfo] = useState(null);

  const getProductsBySearchTerm = (inputValue, callback, pgNumber) => {
    if (inputValue && inputValue.length > 0) {
      const endpoint = `${type}/products`;

      getService(endpoint, {
        term: inputValue,
        limit: 50,
        page: pgNumber,
      }).then((response) => {
        const filteredByType = response.data.filter(
          (item) => handleDemandTypeByEntityType(type) === item.type
        );

        const mappedData = filteredByType.map((item) => {
          return {
            ...item,
            value: item.productId,
            label: item.title,
          };
        });

        setStateProducts({
          ...stateProducts,
          load: mappedData,
        });
        callback(mappedData);
        setPageInfo(response?.pagingInfo);
      });
    }
  };

  const handleReset = () => {
    handleClear();
    setSearchTerm("");
    setStateProducts(initialStateProducts);
    setPageInfo(null);
  };

  const handleChangeSetOriginalProducts = (item) => {
    if (!item) return handleReset();

    handleChange(item);
    setStateProducts({ ...stateProducts, create: item || [] });
  };

  // TODO: funcionalidade não é executada, mas será mantida para futuras implementações
  useEffect(() => {
    const ReactSelect = document.querySelector(".reactSelect__menu-list");
    ReactSelect &&
      ReactSelect.addEventListener("scroll", () => {
        const mathScroll = ReactSelect.scrollHeight - ReactSelect.scrollTop;
        if (mathScroll < 500 && pageInfo?.totalPages > pageInfo?.currentPage) {
          getProductsBySearchTerm(
            searchTerm?.inputValue,
            searchTerm?.callback,
            ++pageInfo.currentPage
          );
        }
      });
  }, [stateProducts]);

  useEffect(() => {
    const searchDebounce = setTimeout(() => {
      getProductsBySearchTerm(searchTerm?.inputValue, searchTerm?.callback, 1);
    }, 1000);

    return () => clearTimeout(searchDebounce);
  }, [searchTerm]);

  return (
    <div className="SelectOriginalProduct">
      <AsyncSelect
        value={stateProducts.create}
        loadOptions={(inputValue, callback) =>
          setSearchTerm({ inputValue, callback })
        }
        onChange={handleChangeSetOriginalProducts}
        className="reactSelect"
        classNamePrefix="reactSelect"
        components={{
          LoadingIndicator: () => <AsyncLoading />,
          Option,
        }}
        noOptionsMessage={() => "Nenhum item encontrado"}
        loadingMessage={() => "Carregando..."}
        placeholder={placeholder}
        isClearable={isClearable}
        openMenuOnClick={false}
      />
    </div>
  );
};

export default SelectOriginalProduct;
