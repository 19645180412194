import { transformMaterialList } from "./utils";

export const compileOptions = (listItems) => {
  const identifyList = !!listItems?.blocks?.length
  ? listItems?.blocks
  : listItems;

  if (!!listItems?.blocks?.length) {
    return identifyList
      ?.map((block) =>
        block?.attachments?.map((item) => ({
          id: item?.attachment?.id,
          value: `Bloco ${block?.blockNumber} - Capítulo ${item?.attachment?.chapterNumber}`,
          block: block?.blockNumber,
          chaption: item?.attachment?.chapterNumber,
          version: item?.attachment?.version,
          numberChaptersBlock: block?.numberChaptersBlock,
          rescheduledDelivery: block?.rescheduledDelivery,
          rescheduledRelease: block?.rescheduledRelease,
          scheduleDelivery: block?.scheduleDelivery,
          scheduleRelease: block?.scheduleRelease,
        }))
      )
      ?.flat();
  } else {
    return identifyList?.episodes
      ?.map((episode) =>
        episode?.attachments?.map((item) => ({
          id: item?.attachment?.id,
          value: `Episodio ${item?.attachment?.chapterNumber}`,
          chaption: item?.attachment?.chapterNumber,
          version: item?.attachment?.version,
          numberAttachmentsEpisodes: episode?.numberAttachmentsEpisodes,
          rescheduledDelivery: episode?.rescheduledDelivery,
          rescheduledRelease: episode?.rescheduledRelease,
          scheduleDelivery: episode?.scheduleDelivery,
          scheduleRelease: episode?.scheduleRelease,
        }))
      )
      ?.flat();
  }
}

export const compileMaterialsOptions = (listItems) => {
  const filteredList = transformMaterialList(listItems.episodes);

  if (!filteredList.length) return [];

  const validAttachmentTypes = [1209, 1210, 18738];
  const filteredEpisodes = filteredList.filter((episode) => 
    validAttachmentTypes.includes(episode?.attachmentType?.id)
  );

  const episodesData = filteredEpisodes.map((episode) => {
    const attachmentTypeId = episode?.attachmentType?.id;
    const episodeNumber = episode?.episodeNumber;
    const version = episode?.version;
    
    const versions = episode?.attachments?.map((attachment) => {
      return {
        id: attachment.attachment.id,
        fileName: attachment.attachment.fileName,
        version: attachment.attachment.version,
        receivedAt: attachment.attachment.receivedAt,
      };
    }) || [];

    return { attachmentTypeId, episodeNumber, versions, version };
  });

  const groupedData = episodesData.reduce((acc, { attachmentTypeId, episodeNumber, versions, version }) => {
    if (!acc[attachmentTypeId]) {
      acc[attachmentTypeId] = [];
    }
    acc[attachmentTypeId].push({ episodeNumber, versions, version });
    return acc;
  }, {});

  return groupedData;
};
