import React, { useState, useEffect, useContext, useCallback } from "react";
import "./styles/ChaptersAndEpisodes.scss";
import { deleteService, getService } from "../../services/script-api";
import TableLoading from "../../components/TableLoading/TableLoading";
import LoadError from "../../components/LoadError/LoadError";
import { sendEvent } from "../../services/GoogleAnalytics/methods";
import NoBlocksOrEpisodes from "./BlocksOrChaptersTable/NoBlocksOrEpisodes/NoBlocksOrEpisodes";
import ScriptBox from "../../components/ScriptBox/ScriptBox";
import CreateAndUpdateBlockPlanning from "./CreateAndUpdateBlockPlanning/CreateAndUpdateBlockPlanning";
import EditIcon from "../../components/VectorIcons/EditIcon";
import AddMore from "../../components/AddMore/AddMore";
import AppContext from "../../context/appContext";
import UploadBox from "../../components/UploadBox/UploadBox";
import ReleaseTextsModal from "../ProjectSheet/ListFiles/ReleaseTextsModal/ReleaseTextsModal";
import SimpleScriptBox from "../ProjectSheet/SimpleScriptBox/SimpleScriptBox";
import EditChaptersOrEpisodes from "./EditChaptersOrEpisodes/EditChaptersOrEpisodes";
import ConfirmationBox from "../../components/ConfirmationBox/ConfirmationBox";
import BlocksTable from "../../views/ChaptersAndEpisodes/BlocksOrChaptersTable/BlocksTable"
import BlocksOrChaptersTable from "../../views/ChaptersAndEpisodes/BlocksOrChaptersTable/BlocksOrChaptersTable"
import FileDetailsChaptersOrEpisodes from "./FileDetailsChaptersOrEpisodes/FileDetailsChapterOrEpisodes";
import MaterialsTable from "./BlocksOrChaptersTable/MaterialsTable";
import CreateAndUpdateBlockPlanningMaterials from "./CreateAndUpdateBlockPlanning/CreateAndUpdateBlockPlanningMaterials";
import { transformMaterialList } from "./utils";

const ChaptersAndEpisodes = (props) => {
  const {
    chapterOrEpisode,
    contentId,
    type = "projectSheet",
    getCallback,
    isReleased = false
  } = props;

  const appContext = useContext(AppContext);

  const [statePlanning, setStatePlanning] = useState({
    loading: true,
    data: null,
  });
  const [stateAction, setStateAction] = useState({
    type: "create",
    data: null,
  });
  const [stateError, setStateError] = useState(false);
  const [stateReleaseIsOpen, setStateReleaseIsOpen] = useState(false);
  const [stateRelease, setStateRelease] = useState({ isOpen: false });

  const [stateEditFile, setStateEditFile] = useState({
    isOpen: false,
    current: null,
  });

  const [stateFileDetails, setStateFileDetails] = useState({
    isOpen: false,
    current: null,
  });

  const [stateDeleteFile, setStateDeleteFile] = useState({
    isOpen: false,
    currentFile: null,
  });

  const [haveEpisodes, setHaveEpisodes] = useState(false);

  const isChapter = chapterOrEpisode === 'Capítulos'
  const isMaterial = chapterOrEpisode === 'Materiais'
  const totalBlocks = statePlanning.data?.blocks?.length;
  const isEpisode = chapterOrEpisode === 'Episódios' || chapterOrEpisode === 'EpisódiosVariedades'


  const totalEpisodesCounter = () => {
    let episodes = [];
    const allEpisodes = statePlanning.data?.episodes;
    if (allEpisodes?.length) {
      allEpisodes.forEach((episode) => {
        const isEpisodeNotIncluded = !episodes.includes(episode.episodeNumber);
        if (isEpisodeNotIncluded)
          episodes = [...episodes, episode.episodeNumber];
      });
    }
    return episodes.length;
  };

  const lastEpisodeReduce = statePlanning.data?.episodes?.reduce(
    (max, current) =>
      max >= current.episodeNumber ? max : current.episodeNumber,
    0
  );

  const returnNextValidEpisode = () => {
    if (!statePlanning.data?.episodes?.length) return 1;
    return lastEpisodeReduce + 1;
  };

  const returnNextValidEpisodeForMaterial = (typeId) => {
    const episodes = transformMaterialList(statePlanning.data?.episodes) || [];

    const filteredEpisodes = episodes.filter((episode) => 
      episode?.attachmentType?.id === parseInt(typeId)
    );

    if (!filteredEpisodes.length) return 1;

    return (
      filteredEpisodes.reduce(
        (max, current) => (max >= current.version ? max : current.version),
        0
      ) + 1
    );
  };

  const planningTypes = {
    Capítulos: {
      entity: "bloco",
      chapterOrEpisode: "capítulo",
      endpoints: {
        get: "planning/dramaturgylong",
        post: "planning/dramaturgylong/attachments",
        put: "planning/dramaturgylong",
      },
      data: statePlanning.data?.blocks || [],
      messages: {
        uploadMessage: "dos arquivos de capítulos aqui",
        createMessage: "+ Criar Outro Bloco/Capítulos",
        noAttachmentsMessage:
          type === "projectSheet"
            ? "Nenhum anexo vinculado a este capítulo"
            : "Nenhum texto liberado com anexo",
        postMessages: {
          success: "Bloco cadastrado com sucesso!",
          error: "Erro ao cadastrar bloco",
        },
        putMessages: {
          success: "Bloco editado com sucesso!",
          error: "Erro ao editar bloco",
        },
      },
      analytics: {
        get: {
          category: "Projeto de Dramaturgia",
          action: "Acesso aos Capítulos",
        },
        post: {
          category: "Projeto de Dramaturgia",
          action: "Cadastro de bloco",
        },
        put: {
          category: "Projeto de Dramaturgia",
          action: "Edição de planejamento de bloco",
          label: "Campos editados",
        },
      },
      totalBlocks: totalBlocks || 0,
      nextValidBlock: !!totalBlocks
        ? statePlanning.data?.blocks[totalBlocks - 1]?.blockNumber + 1
        : 1,
    },
    Episódios: {
      entity: "episódio",
      chapterOrEpisode: "episódio",
      endpoints: {
        get: "planning/dramaturgyshort",
        post: "planning/dramaturgyshort/attachments",
        put: "planning/dramaturgyshortbyid",
      },
      data: statePlanning.data?.episodes || [],
      messages: {
        uploadMessage: "dos arquivos de episódios aqui",
        createMessage: "+ Criar Episódios",
        noAttachmentsMessage: "Nenhum anexo vinculado a este episódio",
        postMessages: {
          success: "Episódio cadastrado com sucesso!",
          error: "Erro ao cadastrar episódio",
        },
        putMessages: {
          success: "Episódio editado com sucesso!",
          error: "Erro ao editar episódio",
        },
      },
      analytics: {
        get: {
          category: "Projeto de Dramaturgia",
          action: "Acesso aos Episódios",
        },
        post: {
          category: "Projeto de Dramaturgia",
          action: "Cadastro de episódio",
        },
        put: {
          category: "Projeto de Dramaturgia",
          action: "Edição de planejamento de episódio",
          label: "Campos editados",
        },
      },
      totalEpisodes: totalEpisodesCounter(),
      nextValidEpisode: returnNextValidEpisode(),
    },
    EpisódiosVariedades: {
      entity: "episódio",
      chapterOrEpisode: "episódio",
      endpoints: {
        get: "planning/variety",
        post: "planning/variety/attachments",
        put: "planning/variety",
      },
      data: statePlanning.data?.episodes || [],
      messages: {
        uploadMessage: "dos arquivos de episódios aqui",
        createMessage: "+ Criar Episódios",
        noAttachmentsMessage: "Nenhum anexo vinculado a este episódio",
        postMessages: {
          success: "Episódio cadastrado com sucesso!",
          error: "Erro ao cadastrar episódio",
        },
        putMessages: {
          success: "Episódio editado com sucesso!",
          error: "Erro ao editar episódio",
        },
      },
      analytics: {
        get: {
          category: "Projeto de Variedades",
          action: "Acesso aos Episódios",
        },
        post: {
          category: "Projeto de Variedades",
          action: "Cadastro de episódio",
        },
        put: {
          category: "Projeto de Variedades",
          action: "Edição de planejamento de episódio",
          label: "Campos editados",
        },
      },
      totalEpisodes: totalEpisodesCounter(),
      nextValidEpisode: returnNextValidEpisode(),
    },
    Materiais: {
      entity: "",
      chapterOrEpisode: "",
      endpoints: {
        get: "planning/movies",
        post: "planning/movies/attachments",
        put: "planning/movies",
      },
      data: statePlanning.data?.episodes || [],
      messages: {
        uploadMessage: "dos arquivos aqui",
        createMessage: "+ Criar Planejamento",
        noAttachmentsMessage: "Nenhum planejamento criado para este material",
        noLinkedFileMessage: "Nenhum anexo vinculado a este material",
        postMessages: {
          success: "Planejamento cadastrado com sucesso!",
          error: "Erro ao cadastrar planejamento",
        },
        putMessages: {
          success: "Planejamento editado com sucesso!",
          error: "Erro ao editar planejamento",
        },
      },
      analytics: {
        get: {
          category: "Projeto de Dramaturgia",
          action: "Acesso aos Episódios",
        },
        post: {
          category: "Projeto de Dramaturgia",
          action: "Cadastro de planejamento",
        },
        put: {
          category: "Projeto de Dramaturgia",
          action: "Edição de planejamento",
          label: "Campos editados",
        },
      },
      totalEpisodes: totalEpisodesCounter(),
      nextValidArgumentEpisode: returnNextValidEpisodeForMaterial,
    },
  };

  const createAndEditTypes = {
    create: {
      modalTitle: isMaterial ? "Criar Planejamento" : "Criar",
      icon: "",
    },
    edit: {
      modalTitle: "Editar",
      icon: <EditIcon color="#FFF" />,
    },
  };

  const handleDate = (date) => {
    if (date) return `${appContext.convertDatePtBr(date, 'AA')}`;
    return <span className="datePlaceholder">{"-"}</span>;
  };

  const handleCreate = () => {
    appContext.setCurrentStepBox("CreateAndUpdateBlockPlanning");
    setStateAction({ type: "create", data: null });
  };

  const handleEditFile = (item) => {
    appContext.setCurrentStepBox("editChaptersOrEpisodes");
    setStateEditFile({
      current: item,
    });
  };

  const handleFileDetails = (item) => {
    appContext.setCurrentStepBox("fileDetailsChaptersOrEpisodes");
    setStateFileDetails({
      dates: [
        handleDate(item.receivedAt),
        handleDate(item.attachedContentSight),
        handleDate(item.attachedProdutionSight),
        handleDate(item.attachedArtisticApproval),
        handleDate(item.releaseDate),
        handleDate(item.attachedChannelFeedback),
      ],
      fileName: item.fileName
    });
  };


  const handleOpenReleaseTexts = (currentAttachment, versions) => {
    const copyCurrent = { ...currentAttachment };
    setStateReleaseIsOpen(true);
    setStateRelease({
      isOpen: true,
      current: copyCurrent,
      versions,
    });
  };

  const handleConfirmationDeleteFile = (item) => {
    setStateDeleteFile({
      open: true,
      currentFile: item,
    });
  };

  const closeDeleteConfirmation = () => {
    setStateDeleteFile({
      currentFile: null,
      open: false,
    });
  };

  const handleDeleteFile = (id) => {
    appContext.setShowGlassLoading(true);

    deleteService("contents/attachments", id)
      .then(() => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(`Capítulo excluído com sucesso`);
        getPlanning();
      })
      .catch(() => {
        appContext.setShowGlassLoading(false);
      });
  };


  const notLoadingAndHasContent =
    !statePlanning.loading && !!planningTypes[chapterOrEpisode].data?.length;

  const getEndpoint = planningTypes[chapterOrEpisode].endpoints.get;

  const getPlanning = useCallback(() => {
    getService(getEndpoint, {
      origins: [1, 2],
      id: contentId,
      onlyRelease: type === "channels" ? 1 : 0,
    })
      .then((response) => {

        const hasData = Array.isArray(response.data) && response.data.length > 0;
        const firstData = hasData ? response.data[0] : null;
        const isShortDramaturgy = firstData && Array.isArray(firstData.episodes) && firstData.episodes.length > 0;

        setStatePlanning({
          loading: false,
          data: hasData ? firstData : [],
        });

        setHaveEpisodes(isShortDramaturgy && firstData.episodes[0]?.episodeNumber !== null);

      })
      .catch(() => {
        setStatePlanning((prevState) => ({ ...prevState, loading: false }));
        setStateError(true);
      });
  }, [getEndpoint, contentId, type]);

  useEffect(() => {
    getPlanning();
  }, [getPlanning]);

  useEffect(() => {
    if (typeof getCallback === "function" && statePlanning?.data)
      getCallback(statePlanning?.data);
  }, [getCallback, statePlanning]);

  useEffect(() => {
    sendEvent(
      planningTypes[chapterOrEpisode].analytics.get.category,
      planningTypes[chapterOrEpisode].analytics.get.action
    );
  }, [planningTypes, chapterOrEpisode]);

  return (
    <div className="chaptersAndEpisodes">
      {statePlanning.loading && <TableLoading />}

      {type === "projectSheet" && notLoadingAndHasContent && (
        <UploadBox
          title={planningTypes[chapterOrEpisode].messages.uploadMessage}
          projectId={contentId}
          listItems={statePlanning?.data}
          getPlanning={getPlanning}
          isChapter={isChapter}
          isMaterial={isMaterial}
        />
      )}

      {notLoadingAndHasContent && !isChapter && chapterOrEpisode !== 'Materiais' &&(
        <>

          {haveEpisodes && (
            <BlocksTable
              chapterOrEpisode={chapterOrEpisode}
              list={planningTypes[chapterOrEpisode].data}
              setStateAction={setStateAction}
              typeValues={planningTypes[chapterOrEpisode]}
              getPlanning={getPlanning}
              handleEditFile={handleEditFile}
              handleFileDetails={handleFileDetails}
              handleOpenReleaseTexts={handleOpenReleaseTexts}
              handleDeleteChapter={handleConfirmationDeleteFile}
              type={type}
              projectTitle={statePlanning.data?.title}
              isReleased={isReleased}
            />
          )}

          {type === "projectSheet" && (
            <div onClick={() => handleCreate()}>
              <AddMore
                text={planningTypes[chapterOrEpisode].messages.createMessage}
              />
            </div>
          )}
        </>
      )}

      {notLoadingAndHasContent && isMaterial &&(
        <>
          <MaterialsTable
            chapterOrEpisode={chapterOrEpisode}
            list={transformMaterialList(planningTypes[chapterOrEpisode].data)}
            setStateAction={setStateAction}
            typeValues={planningTypes[chapterOrEpisode]}
            getPlanning={getPlanning}
            handleEditFile={handleEditFile}
            handleFileDetails={handleFileDetails}
            handleOpenReleaseTexts={handleOpenReleaseTexts}
            handleDeleteMaterial={handleConfirmationDeleteFile}
            type={type}
            projectTitle={statePlanning.data?.title}
            isReleased={isReleased}
          />

          {type === "projectSheet" && (
            <div onClick={() => handleCreate()}>
              <AddMore
                text={planningTypes[chapterOrEpisode].messages.createMessage}
              />
            </div>
          )}
        </>
      )}

      {notLoadingAndHasContent && isChapter && (
        <>
          {(haveEpisodes || !isEpisode) &&
            <BlocksOrChaptersTable
              chapterOrEpisode={chapterOrEpisode}
              list={planningTypes[chapterOrEpisode].data}
              setStateAction={setStateAction}
              typeValues={planningTypes[chapterOrEpisode]}
              getPlanning={getPlanning}
              handleEditFile={handleEditFile}
              handleOpenReleaseTexts={handleOpenReleaseTexts}
              handleDeleteChapter={handleConfirmationDeleteFile}
              type={type}
              projectTitle={statePlanning.data?.title}
            />}

          {type === "projectSheet" && (
            <div onClick={() => handleCreate()}>
              <AddMore
                text={planningTypes[chapterOrEpisode].messages.createMessage}
              />
            </div>
          )}

        </>
      )}

      {!statePlanning.loading &&
        !stateError &&
        !planningTypes[chapterOrEpisode].data?.length && (
          <NoBlocksOrEpisodes
            projectType={chapterOrEpisode}
            type={type}
            handleCreate={handleCreate}
          />
        )}

      {stateError && <LoadError />}

      {appContext.current.step === "CreateAndUpdateBlockPlanning" && (
        <ScriptBox
          open={appContext.current.step === "CreateAndUpdateBlockPlanning"}
          title={createAndEditTypes[stateAction.type].modalTitle}
          icon={createAndEditTypes[stateAction.type].icon}
          marginBottom={true}
          allowOverflow={true}
          removePadding={true}
          size="medium"
        >
          {chapterOrEpisode === "Materiais" ? (
            <CreateAndUpdateBlockPlanningMaterials
              action={stateAction}
              planningData={planningTypes[chapterOrEpisode]}
              contentId={contentId}
              getPlanning={getPlanning}
              chapterOrEpisode={chapterOrEpisode}
              nextValidArgumentEpisode={planningTypes[chapterOrEpisode].nextValidArgumentEpisode}
            />
          ) : (
            <CreateAndUpdateBlockPlanning
              action={stateAction}
              planningData={planningTypes[chapterOrEpisode]}
              contentId={contentId}
              getPlanning={getPlanning}
              chapterOrEpisode={chapterOrEpisode}
            />
          )}
        </ScriptBox>
      )}

      {appContext.current.step === "fileDetailsChaptersOrEpisodes" && (

        <SimpleScriptBox
          open={appContext.current.step === "fileDetailsChaptersOrEpisodes"}
          toggleScriptBox={() => appContext.setCurrentStepBox("")}
          title={stateFileDetails.fileName}
          icon={
            <svg width="28px" height="28px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="48" height="48" fill="white" fillOpacity="0.01"></rect> <path d="M40 27V6C40 4.89543 39.1046 4 38 4H10C8.89543 4 8 4.89543 8 6V42C8 43.1046 8.89543 44 10 44H21" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M17 12L31 12" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M17 20L31 20" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M17 28H23" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M37 37C37 38.3807 36.4404 39.6307 35.5355 40.5355C34.6307 41.4404 33.3807 42 32 42C29.2386 42 27 39.7614 27 37C27 34.2386 29.2386 32 32 32C34.7614 32 37 34.2386 37 37Z" fill="#e2e4ff"></path> <path d="M39 44L35.5355 40.5355M35.5355 40.5355C36.4404 39.6307 37 38.3807 37 37C37 34.2386 34.7614 32 32 32C29.2386 32 27 34.2386 27 37C27 39.7614 29.2386 42 32 42C33.3807 42 34.6307 41.4404 35.5355 40.5355Z" stroke="#0098cd" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
          }
          removePadding={true}
          allowOverflow={true}
        >
          <FileDetailsChaptersOrEpisodes
            file={stateFileDetails.dates}
          />
        </SimpleScriptBox>
      )}

      {appContext.current.step === "editChaptersOrEpisodes" && (
        <SimpleScriptBox
          open={appContext.current.step === "editChaptersOrEpisodes"}
          toggleScriptBox={() => appContext.setCurrentStepBox("")}
          title={stateEditFile.current.fileName}
          icon={<EditIcon color="#FFF" />}
          //removePadding={true}
          allowOverflow={false}
        >
          <EditChaptersOrEpisodes
            file={stateEditFile.current}
            thesaurusFileType={true}
            darkBackgroundColor={false}
            listItems={statePlanning?.data}
            reload={getPlanning}
            isChapter={isChapter}
            isMaterial={isMaterial}
          />
        </SimpleScriptBox>
      )}

      {stateReleaseIsOpen && (
        <ReleaseTextsModal
          releaseTexts={stateRelease}
          setStateOpen={setStateReleaseIsOpen}
          reload={getPlanning}
          projectType="Dramaturgia"
          submitCallback={() => setStateReleaseIsOpen(false)}
          showReleaseVersions={!isMaterial}
        />
      )}

      {stateDeleteFile.open && (
        <ConfirmationBox
          open={stateDeleteFile.open}
          textButton="Sim, excluir"
          closedBox={closeDeleteConfirmation}
          handleEvent={() => handleDeleteFile(stateDeleteFile.currentFile)}
          title="Tem certeza?"
          text={`Este ${planningTypes[chapterOrEpisode].chapterOrEpisode} será excluído permanentemente do sistema e essa ação não poderá ser desfeita`}
        />
      )}
    </div>
  );
};

export default ChaptersAndEpisodes;
