import React, { useCallback, useContext, useEffect, useState } from "react";
import "./styles/MaterialsList.scss";
import DownloadIcon from "../../../../components/VectorIcons/DownloadIcon";
import EditIcon from "../../../../components/VectorIcons/EditIcon";
import ReleaseTextIcon from "../../../../components/VectorIcons/ReleaseTextIcon";
import ReleaseTextIconMarked from "../../../../components/VectorIcons/ReleaseTextIconMarked";
import RegisterContext from "../../../../context/registerContext";
import ConfirmationBox from "../../../../components/ConfirmationBox/ConfirmationBox";
import { deleteService } from "../../../../services/script-api";
import AppContext from "../../../../context/appContext";
import DeleteIcons from "../../../ProjectSheet/ListFiles/BlockList/DeleteIcons/DeleteIcons";
import TypeCell from "../TypeCell/TypeCell";
import TableItemDatesReleasedText from "../TableItemDatesReleasedText/TableItemDatesReleasedText";
import TableItemDates from "../TableItemDates/TableItemDates";
import DeleteIcon from "../../../../components/VectorIcons/DeleteIcon";

const MaterialsList = (props) => {
  const {
    list,
    handleDate,
    handleEditFile,
    handleOpenReleaseTexts,
    getPlanning,
    handleFileDetails,
    dates,
    isReleased,
    type,
    isChapter,
    isLastItem,
    handleDeleteMaterial,
    setStateAction,
  } = props;

  const [stateIsOpen, setStateIsOpen] = useState(false);

  const registerContext = useContext(RegisterContext);
  const appContext = useContext(AppContext);

  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [isFile, setFile] = useState();

  const handleConfirmationBox = (file) => {
    setFile(file);
    setOpenConfirmationBox(true);
  };

  const closedBox = () => {
    setOpenConfirmationBox(false);
  };

  const handleDeleteFile = (file) => {
    appContext.setShowGlassLoading(true);

    deleteService("contents/attachments", file.id)
      .then((response) => {
        if (response.status === 200 || response === "") {
          appContext.sendMessage(`Arquivo ${file.fileName} deletado com sucesso`);
          setOpenConfirmationBox(false);
          getPlanning();
        } else {
          appContext.sendMessage(`Erro ao deletar arquivo: ${response.statusText}`, "error");
          console.error("Erro ao deletar arquivo:", response);
        }
        appContext.setShowGlassLoading(false);
      })
      .catch((error) => {
        appContext.setShowGlassLoading(false);
        appContext.sendMessage(`Erro ao deletar arquivo`, "error");
        console.log("error: ", error);
      });
  };

  const returnColor = (count) => (!!count ? "#7f808e" : "#ec1045");

  const handleResize = useCallback(() => {
    if (
      window.innerWidth > 1024 &&
      appContext.current.step === "fileDetailsChaptersOrEpisodes"
    ) {
      appContext.setCurrentStepBox("");
    }
  }, [appContext]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const toggleIsOpen = () => setStateIsOpen((prevState) => !prevState);
  const hasAttachments = list?.attachments?.some((episode) => {
    return (
      episode?.attachment?.fileId ||
      episode?.olderVersions?.some((version) => !!version.fileId)
    );
  });
  const isEpisodeDeleteable = isLastItem && !hasAttachments;
  const episodeId = list?.id;

  const handleEdit = (item) => {
    appContext.setCurrentStepBox("CreateAndUpdateBlockPlanning");
    setStateAction({ type: "edit", data: item });
  };

  return (
    <div className={`versionsContainer ${stateIsOpen ? "open" : ""}`}>
      <div className="versionsHeader" onClick={toggleIsOpen}>
        <TypeCell
          isChapter={false}
          isMaterial={true}
          mainCellTitle={`${list.version}ª Versão`}
        />
        <div className="releaseDate">
          {isReleased ? (
            <TableItemDatesReleasedText
              scheduleDelivery={dates.scheduleDelivery}
              rescheduledDelivery={dates.rescheduledDelivery}
              lastAttachmentReceiveDate={dates.lastAttachmentReceiveDate}
              scheduleRelease={dates.scheduleRelease}
              rescheduledRelease={dates.rescheduledRelease}
              lastAttachmentReleaseDate={dates.lastAttachmentReleaseDate}
              type={type}
            />
          ) : (
            <TableItemDates
              handleDate={handleDate}
              dates={dates}
              type={type}
              isChapter={isChapter}
            />
          )}
        </div>
        <div className="actions">
          <div onClick={() => handleEdit(list)}>
            <EditIcon />
          </div>
          {isLastItem && isEpisodeDeleteable && (
            <div onClick={() => handleDeleteMaterial(episodeId)}>
              <DeleteIcon />
            </div>
          )}
        </div>
      </div>

      {list?.attachments?.filter((item) => item.attachment && item.attachment.fileId)
        .map((item, index) => {
          const { attachment, olderVersions } = item;
          const allVersions = [attachment, ...olderVersions];
          return (
            <ul className="versionsList" key={index}>
              {allVersions.map((file, idx) => {
                return (
                  <li className="versionItem" key={idx}>
                    <div className="versionInfo">
                      <div className="fileNameBlock">
                        <span className="file">{file.fileName || "Sem nome"}</span>
                        {file.reason && (
                          <span className="reason">
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8 3H6C4.89543 3 4 3.89543 4 5V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V5C20 3.89543 19.1046 3 18 3H14M8 3V12L11 9L14 12V3M8 3H14"
                                stroke="#333333"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                            <div style={{ marginTop: "4px", marginLeft: "4px" }}>
                              {file.reason}
                            </div>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="actions">
                      <div
                        className="details"
                        onClick={() => handleFileDetails(file)}
                      >
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M40 27V6C40 4.89543 39.1046 4 38 4H10C8.89543 4 8 4.89543 8 6V42C8 43.1046 8.89543 44 10 44H21"
                            stroke="#0098cd"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17 12L31 12"
                            stroke="#0098cd"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17 20L31 20"
                            stroke="#0098cd"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div onClick={() => handleEditFile(file)}>
                        <EditIcon />
                      </div>
                      <span
                        onClick={() =>
                          registerContext.getUrlS3(file.fileId, file.fileName)
                        }
                      >
                        <DownloadIcon />
                      </span>
                      <div
                        onClick={() =>
                          handleOpenReleaseTexts(
                            file,
                            list.attachments.filter(
                              (att) => att.attachment.fileId !== file.fileId
                            )
                          )
                        }
                      >
                        {file.release ? <ReleaseTextIconMarked /> : <ReleaseTextIcon />}
                      </div>
                      {list.attachments && (
                        <span
                          onClick={() =>
                            !file.release &&
                            !file.activityCount &&
                            handleConfirmationBox(file)
                          }
                        >
                          <DeleteIcons
                            release={file.release}
                            activityCount={file.activityCount}
                            returnColor={returnColor}
                          />
                        </span>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          );
        })}

      {openConfirmationBox && (
        <ConfirmationBox
          open={openConfirmationBox}
          textButton="Sim, excluir"
          closedBox={closedBox}
          handleEvent={() => handleDeleteFile(isFile)}
          title="Tem certeza?"
          text="Este anexo será excluído permanentemente do sistema e essa ação não poderá ser desfeita"
        />
      )}
    </div>
  );
};

export default MaterialsList;
