export const transformMaterialList = (list) => {
  return list.reduce((acc, item) => {
    const { attachmentType, version, attachments = [] } = item;

    let existingItem = acc.find(
      (i) => i.attachmentType.id === attachmentType.id && i.version === version
    );

    if (!existingItem) {
      existingItem = { ...item, attachments: [] };
      acc.push(existingItem);
    }

    attachments.forEach(({ attachment, olderVersions }) => {
      if (attachment.version === version) {
        existingItem.attachments.push({ attachment, olderVersions: [] });
      } else {
        let correctParent = acc.find(
          (i) => i.attachmentType.id === attachment.attachmentType.id && i.version === attachment.version
        );

        if (!correctParent) {
          correctParent = { ...item, version: attachment.version, attachments: [] };
          acc.push(correctParent);
        }

        correctParent.attachments.push({ attachment, olderVersions: [] });
      }

      if (olderVersions) {
        olderVersions.forEach((olderAttachment) => {
          if (olderAttachment.version === version) {
            existingItem.attachments.push({ attachment: olderAttachment, olderVersions: [] });
          } else {
            let correctOlderParent = acc.find(
              (i) => i.attachmentType.id === olderAttachment.attachmentType.id && i.version === olderAttachment.version
            );

            if (!correctOlderParent) {
              correctOlderParent = { ...item, version: olderAttachment.version, attachments: [] };
              acc.push(correctOlderParent);
            }

            correctOlderParent.attachments.push({ attachment: olderAttachment, olderVersions: [] });
          }
        });
      }
    });

    return acc;
  }, []);
};